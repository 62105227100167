import React from 'react';

const ArrowRight = () => (
	<svg
		width="28px"
		height="56px"
		viewBox="0 0 28 56"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="arrow_right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
			<g id="Path" stroke="#D5D5D5" strokeWidth="2">
				<polyline points="0 0 28 28 0 56"></polyline>
			</g>
		</g>
	</svg>
);

export default ArrowRight;
